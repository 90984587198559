@charset "UTF-8";
[data-v-6d65e421] .el-dialog {
  max-height: calc(100% - 20px);
  background: #f9fafc !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 32px 60px;
  border-radius: 10px;
  display: block;
}
[data-v-6d65e421] .el-dialog .el-dialog__header {
    line-height: 34px;
    -webkit-box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
            box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
    background: #fff !important;
    border-radius: 20px 24px 10px 10px;
    overflow: hidden;
}
[data-v-6d65e421] .el-dialog .el-dialog__body {
    -webkit-box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
            box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px !important;
    height: calc(100% - 140px);
}
[data-v-6d65e421] .el-dialog .el-dialog__footer {
    text-align: center;
}
[data-v-6d65e421] .el-dialog .el-dialog__headerbtn .el-dialog__close {
    color: #000;
}
.my-viewer-title-img[data-v-6d65e421] {
  width: 100%;
  height: 20px;
  background-image: url("https://file.qingflow.com/assets/widget/theme/header0.png");
  background-size: cover;
}
.my-viewer-title[data-v-6d65e421] {
  color: #000;
  width: 100%;
  padding: 16px;
  height: 34px;
}
.my-viewer-title span[data-v-6d65e421] {
    font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Microsoft Yahei UI", "Microsoft Yahei", sans-serif font-feature-settings "tnum", "tnum";
    font-size: 20px;
    font-weight: 600;
}
[data-v-6d65e421] .cus-data-viewer {
  height: 100%;
}
.main-left-tree-wrapper[data-v-6d65e421] {
  height: 100%;
  margin-right: 10px;
}
.tab[data-v-6d65e421] {
  background: #fff;
  height: 50px;
  padding-left: 10px;
}
.page-search-bar[data-v-6d65e421] {
  padding: 10px 0px 10px 10px;
  background-color: #fff;
  margin-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
}
.page-search-bar div[data-v-6d65e421] {
    margin-right: 10px;
}
.page-content[data-v-6d65e421] {
  height: calc(100% - 140px);
}
@media (max-width: 1700px) {
.page-content[data-v-6d65e421] {
    height: calc(100% - 220px);
}
}
[data-v-6d65e421] .el-dropdown {
  cursor: pointer !important;
}
.process-inst-title[data-v-6d65e421] {
  margin-left: 10px;
  cursor: pointer;
}
.process-inst-title[data-v-6d65e421]:hover {
    color: #409eff;
    text-decoration: underline;
}
.img[data-v-6d65e421] {
  position: absolute;
  bottom: 4px;
}
[data-v-6d65e421] .el-tree-node__expand-icon::before {
  content: '\e78a' !important;
  font-size: 18px;
  color: black;
}
[data-v-6d65e421] .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
[data-v-6d65e421] .el-tree-node__expand-icon.expanded::before {
  content: '' !important;
  color: black;
  font-size: 18px;
}
